<template>
  <div class="pc" style="">
    <img class="page_bg" style="" src="../../assets/img/download/other/download_bg.png"/>
    <div style="position: relative; z-index: 1; text-align: center;">
      <img class="img_top" style="width: 78px; height: 78px; margin: 158px auto 0;"
           src="../../assets/img/download/other/logo_top.png"/>
      <p style="display: block; font-size: 20px; margin-top: 20px;">UPLUS智能交易APP</p>
      <p style="display: block; font-size: 12px; margin: 25px auto 0; line-height: unset; color: #B6B8C1; width: 215.5px; word-break: keep-all;">全新期现策略日进斗金</br>速来体验！</p>
      <div class="btn_top" style="margin-top: 106px;">
        <div v-if="isiOS" id="ios_phone">
          <img style="width: 70%; height: 40px;" src="../../assets/img/download/other/ios_y.png" @click="downloadIos()"/>
          <img style="width: 70%; height: 40px; margin-top: 20px;" src="../../assets/img/download/other/android_n.png" @click="downloadAndroid()"/>
        </div>
        <div v-if="isAndroid" id="android_phone">
          <img style="width: 70%; height: 40px;" src="../../assets/img/download/other/android_y.png" @click="downloadAndroid()"/>
          <img style="width: 70%; height: 40px; margin-top: 20px;" src="../../assets/img/download/other/ios_n.png" @click="downloadIos()"/>
        </div>
        <p style="margin-top: 10px; color: #FF4D4F">注：苹果手机用户需要翻墙使用！</p>
      </div>
    </div>
    <p style="color: #fefefe; font-size: 14px; position: absolute; bottom: 40px; left: 50%; transform: translate(-50%, 0);">
      V 3.0</p>
  </div>
</template>

<script>
import download from '../../utils/registered_address'
export default {
  name: "downloadPg",
  data(){
    return{
      isMobile: false,
      isiOS: false,
      isAndroid: false

    }
  },

  created() {
    /** 判断手机是ios/安卓 */
    var userAgent = navigator.userAgent;
    this.isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    this.isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
    if (this.$util._isMobile()){
      this.isMobile = true
    }
  },

  methods: {
    downloadAndroid(){
      const link = document.createElement("a"); //自己创建的a标签
      link.href = download.download_android_address();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // window.URL.revokeObjectURL(res.data);
    },
    downloadIos(){
      const link = document.createElement("a"); //自己创建的a标签
      link.href = download.download_ios_address();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // window.URL.revokeObjectURL(res.data);
    },
  }

}
</script>

<style scoped>
p {
  margin: 0;
  color: #ffffff;
  line-height: 22px;
}

.page_bg {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  margin-bottom: -4px;
  position: absolute;
}

@media screen and (max-width: 320px) {
  .img_top {
    margin-top: 120px !important;
  }

  .btn_top {
    margin-top: 60px !important;
  }
}
</style>
